import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// material design
import './assets/css/materialdesignicons.css'
import './assets/css/fonts.css'
import './assets/css/auth.css'
window.$ = window.jQuery = require('jquery');
import 'jquery'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

require('./assets/chosen/chosen.jquery.min');
require('./assets/chosen/chosen.proto.min');

import './assets/chosen/chosen.min.css';

// axios
import axios from 'axios'

// For Token
require('@/store/subscriber');

//axios.defaults.baseURL = "https://loanspwa.kolastudios.com/api/subscriber/";
//axios.defaults.baseURL = "http://loans.test/api/subscriber/";
axios.defaults.baseURL = "https://loans-pwa-api.specialcase.net/api/subscriber/";
axios.defaults.withCredentials = false;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import VueNumberFormat from 'vue-number-format';

store.dispatch('auth/attempt', localStorage.getItem('token')).then(() => {

    createApp(App)
        .use(store)
        .use(router)
        .use(VueNumberFormat, { precision: 0, prefix: '', suffix: '', decimal: '', thousand: ',', acceptNegative: false, isInteger: true  })
        .mount('#app');

});
